import './App.css';

import React, { useState } from 'react';
import {withAuthenticator} from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import awsmobile from './aws-exports';
import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
import '@aws-amplify/ui-react/styles.css';


Amplify.configure(awsmobile);



function App() {
  
  const [badWords, setBadWords] = useState([]);
  const [user, setUser] = useState(null);


  // handles sign-out process
  const signOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  //loads current user information
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        setUser(currentUser);
      } catch (error) {
        console.error('Error fetching user: ', error);
      }
    };
  
    fetchUser();
  }, []);



  // handles the form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    const inputText = event.target.elements.inputText.value;
    const response = await fetch('https://g2yj2cyfhc.execute-api.eu-west-1.amazonaws.com/test/ner', {
      method: 'POST',
      body: inputText
    });
    const responseData = await response.json();
    const badWords = responseData.badwords;
    setBadWords(badWords);
  };



  return (
    
    <div className="form-container">

      {user && (
      <div className="user-info">
        <span>Signed in as: {user.username}</span>
        <button onClick={signOut}>Sign Out</button>
      </div>
      )}

      
      <h1 class="form-title">AnyCompany - Profanity detector</h1>
      <h2 >Enter some text below and see whether it contains profane language...</h2>
      <form onSubmit={handleSubmit}>
        <input type="text" name="inputText" placeholder="Enter some text"/>
        <button type="submit">Submit</button>
      </form>
      {badWords.length > 0 ? (
        <div>
          <h2>Profane language detected:</h2>
          <ul>
            {badWords.map((word, index) => (
              <li key={index}>{word}</li>
            ))}
          </ul>
        </div>
      ) : (
        <h2>No profane language detected.</h2>
      )}
    </div>
  );
}



export default withAuthenticator(App);

