// configuration for cognito

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_Agjz1AhRN",
    "aws_user_pools_web_client_id": '1a7u829o8ind0km3f08ml058bg',

};

export default awsmobile;